import React from "react";
import { useSelector } from "react-redux";

import { getUserPrefersMedical } from "redux/selectors/user";
import { getShortTitle, MenuType } from "utils/menuTypeUtils";

const MenuTypeDisplay = () => {
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  return (
    <div
      className="px-md py-sm text-sm rounded bg-leafly-white text-black"
      data-testid="menu-type-display"
    >
      {getShortTitle(userPrefersMedical ? MenuType.Med : MenuType.Rec)} only
    </div>
  );
};

export default MenuTypeDisplay;
