import React, { ReactNode } from "react";
import classNames from "classnames";

type Props = {
  ariaLabel?: string;
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  defaultChecked?: boolean;
  id: string;
  label?: string;
  labelOrder?: "before" | "after";
  name?: string;
  onChange?: () => void;
  value?: string | number;
} & React.HTMLAttributes<HTMLLabelElement>;

const Radio: React.FC<Props> = ({
  children,
  className,
  id,
  name,
  value,
  checked,
  label,
  labelOrder = "after",
  onChange,
  defaultChecked,
  ariaLabel,
  ...others
}) => {
  const classes = classNames(className, "radio__label", {
    "radio__label--before": labelOrder === "before",
  });

  return (
    <label htmlFor={id} className={classes} aria-label={ariaLabel} {...others}>
      <div className="radio__container">
        <input
          type="radio"
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          defaultChecked={defaultChecked}
          checked={checked}
        />
        <span className="radio__fill" />
      </div>
      <span>{label || children}</span>
    </label>
  );
};

export default Radio;
