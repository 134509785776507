import { Coordinates } from "custom-types/Coordinates";

const MODIFIERS = {
  km: 1.60934,
  mi: 1,
  miles: 1,
};

enum Units {
  mi = "mi",
  miles = "miles",
  km = "km",
}

type Distance = number | null;
type Unit = keyof typeof Units;

const determineUnit = (countryCode = "US") =>
  countryCode === "US" ? Units.mi : Units.km;

const determineUnitFull = (countryCode = "US") =>
  countryCode === "US" ? Units.miles : Units.km;

const formatDistance = (
  number?: Distance,
  unit: Unit = Units.mi,
  places = 1,
) => {
  const roundedDistance = roundDistance(number, unit, places);
  if (roundedDistance === undefined) {
    return;
  } else {
    return distanceInUnits(roundedDistance, unit);
  }
};

const roundDistance = (
  number?: Distance,
  unit: Unit = Units.mi,
  places = 1,
) => {
  if (number === null || number === undefined) {
    return;
  }

  const distance = (number * MODIFIERS[unit]).toFixed(places);

  if (Number.isInteger(Number(distance) % 10)) {
    return parseFloat(distance).toFixed(0);
  } else {
    return parseFloat(distance);
  }
};

const distanceInUnits = (number: number | string, unit: Unit) =>
  `${number}${unit === Units.miles ? ` ${unit}` : unit}`;

/**
 * Returns a distance formatted to a common level of precision with unit suffix.
 */
export const getDistance = (
  distanceMi?: Distance,
  countryCode?: string,
  full = false,
) =>
  formatDistance(
    distanceMi,
    full ? determineUnitFull(countryCode) : determineUnit(countryCode),
  );

/**
 * Returns a distance with variable precision based on proximity. Any distances
 * less than 10 miles will use higher precision than distances further away.
 */
export const getDistanceCustomRounding = (
  distanceMi?: Distance,
  countryCode?: string,
  full = false,
) =>
  formatDistance(
    distanceMi,
    full ? determineUnitFull(countryCode) : determineUnit(countryCode),
    distanceMi && distanceMi < 10 ? 1 : 0,
  );

/**
 * Returns a rounded distance with variable precision based on proximity. Any distances
 * less than 10 miles will use higher precision than distances further away.
 */
export const getRoundedDistance = (
  distanceMi?: Distance,
  countryCode?: string,
) =>
  roundDistance(
    distanceMi,
    determineUnit(countryCode),
    distanceMi && distanceMi < 10 ? 1 : 0,
  );

const degreesToRadians = (degrees: number) => {
  return degrees * (Math.PI / 180);
};

export const getMilesBetween = (
  locationA: Coordinates,
  locationB: Coordinates,
  unit = Units.miles,
) => {
  if (!locationA || !locationB) {
    return 0;
  }

  const earthRadisKm = 6371;
  const deltaLat = degreesToRadians(locationA.lat - locationB.lat);
  const deltaLon = degreesToRadians(locationA.lon - locationB.lon);
  // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
  const a = (Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)) + (Math.cos(degreesToRadians(locationB.lat)) * Math.cos(degreesToRadians(locationA.lat)) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceKm = earthRadisKm * c;

  if (unit === Units.miles) {
    return distanceKm * 0.621371;
  }

  return distanceKm;
};
