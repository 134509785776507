import React from "react";
import classNames from "classnames";

import CheckIcon from "components/Icons/check_mark.svg";

interface Props {
  progress: number;
}

const OfferProgress: React.FC<Props> = ({ progress }) => {
  const isQualified = progress >= 1;
  return (
    <div className="relative progress-wrapper flex justify-center items-center">
      <span className="absolute">
        <CheckIcon
          width="16"
          height="16"
          className={classNames({
            "text-light-grey": !isQualified,
            "text-notification": isQualified,
          })}
        />
      </span>
      <svg viewBox="0 0 36 36" className="m-[2px] absolute">
        <path
          className="to-be-progress"
          stroke="#e7e7e7"
          strokeWidth="2px"
          strokeDasharray={`0, ${progress * 100}, 100`}
          fill="none"
          d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="progress"
          stroke="#dd007e"
          fill="none"
          strokeLinecap="round"
          strokeWidth="2px"
          strokeDasharray={`${progress * 100}, 100`}
          d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <style jsx>
        {`
          .progress-wrapper {
            width: 24px;
            height: 24px;
          }
          .to-be-progress {
            animation: toBeProgress 1s ease-out backwards;
          }
          .progress {
            animation: progress 1s ease-out forwards;
          }
          .check-transition {
            transition: fill 1s;
          }
          @keyframes progress {
            0% {
              stroke-dasharray: 0 100;
            }
          }
          @keyframes toBeProgress {
            0% {
              stroke-dasharray: 0 0 100;
            }
          }
        `}
      </style>
    </div>
  );
};

export default OfferProgress;
