import classNames from "classnames";

import SolidArrowIcon from "components/Icons/arrows/solid_arrow_down.svg";

export default ({
  className = "",
  direction = "down",
  height = "24",
  width = "24",
  ...others
}: {
  className?: string;
  direction?: "up" | "down" | "left" | "right";
  height?: string;
  width?: string;
}) => (
  <SolidArrowIcon
    aria-label={`Arrow pointing ${direction}`}
    className={classNames("transform", className, {
      "-rotate-90": direction === "right",
      "rotate-180": direction === "up",
      "rotate-90": direction === "left",
    })}
    height={height}
    width={width}
    {...others}
  />
);
