import React from "react";
import classnames from "classnames";

const Spinner: React.FC<{
  width?: number;
  height?: number;
  padding?: boolean;
  color?: "green" | "yellow";
}> = ({ width = 65, height = 65, padding = true, color = "green" }) => (
  <div
    className={classnames("flex items-center justify-center", {
      "py-md": padding,
    })}
  >
    <svg
      className="animate-spinner-rotate"
      width={width}
      height={height}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={classnames("origin-center animate-spinner-dash", {
          "stroke-green": color === "green",
          "stroke-yellow": color === "yellow",
        })}
        style={{
          strokeDasharray: 187,
        }}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  </div>
);

export default Spinner;
