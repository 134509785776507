import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import Link, { LinkProps } from "next/link";

import Spinner from "components/Icons/Spinner";
import IconSvg from "components/IconSvg";

const variants = {
  secondary: "secondary",
  text: "text",
} as const;

type ButtonVariant = keyof typeof variants;

const getElementType = (
  href?: string,
  hasOnClick?: boolean,
  isTypeSubmit?: boolean,
): React.ElementType =>
  href ? Link : isTypeSubmit || hasOnClick ? "button" : "div";

export type ButtonProps = (
  | ButtonHTMLAttributes<HTMLButtonElement>
  | (AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps)
  | AnchorHTMLAttributes<HTMLDivElement>
) & {
  iconFilePath?: string;
  size?: "full";
  variant?: ButtonVariant;
  width?: "full";
  isLoading?: boolean;
  loadingText?: React.ReactNode;
  iconDimensions?: string;
};

/**
 * Buttons are used to indicate actions, interactions, or links.
 *
 * **Primary** buttons are used to indicate a primary call to action. There should be one per page at most.
 *
 * **Secondary** buttons can be used when there are two 'primary' calls to action in a single section, or occasionally to open navigation menus.
 *
 * **Text** buttons don't look like typical buttons — they're just bold, underlined text.
 */
const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
  ButtonProps
>(
  (
    {
      children,
      className,
      iconDimensions,
      iconFilePath,
      isLoading = false,
      loadingText,
      onClick,
      size,
      variant,
      width,
      ...others
    },
    ref,
  ) => {
    const classes = classNames(
      "button",
      { "button--secondary": variant === variants.secondary },
      { "button--text": variant === variants.text },
      { "button--full": width === "full" || size === "full" },
      { "flex justify-center items-center": !!iconFilePath },
      className,
    );

    const iconSize =
      iconDimensions === "large"
        ? { height: "24", width: "24" }
        : { height: "16", width: "16" };

    const loadingOrDisabled =
      isLoading || (others as ButtonHTMLAttributes<HTMLButtonElement>).disabled;

    const Element = getElementType(
      (others as AnchorHTMLAttributes<HTMLAnchorElement>).href,
      !!onClick,
      (others as ButtonHTMLAttributes<HTMLButtonElement>).type === "submit",
    );

    return (
      <Element
        onClick={loadingOrDisabled ? undefined : onClick}
        ref={ref}
        className={classes}
        disabled={loadingOrDisabled}
        {...others}
      >
        {!!iconFilePath && (
          <IconSvg className="mr-xs" {...iconSize} filePath={iconFilePath} />
        )}
        {isLoading ? (
          <div className="flex flex-row items-center">
            <span>{loadingText}</span>
            <div className="pl-2">
              <Spinner padding={false} width={10} height={10} color="yellow" />
            </div>
          </div>
        ) : (
          children
        )}
      </Element>
    );
  },
);

export default Button;
