import { MenuType } from "utils/menuTypeUtils";

import MenuTypeButton from "../MenuTypeButton";
import MenuTypeDisplay from "../MenuTypeDisplay";

const MenuTypeSelection: React.FC<{
  className?: string;
  dispensaryId: number;
  isDualLicense: boolean;
  onMenuTypeChange?: (value: MenuType.Med | MenuType.Rec) => void;
  showSelectInput: boolean;
}> = ({
  className,
  dispensaryId,
  isDualLicense,
  onMenuTypeChange,
  showSelectInput,
}) =>
  isDualLicense ? (
    <div className={className}>
      {showSelectInput ? (
        <MenuTypeButton
          dispensaryId={dispensaryId}
          onMenuTypeChange={onMenuTypeChange}
        />
      ) : (
        <MenuTypeDisplay />
      )}
    </div>
  ) : (
    <></>
  );

export default MenuTypeSelection;
